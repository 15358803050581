import { Divider, Segmented, Slider, Switch } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import { Formatter } from 'antd/es/slider'
import { useTranslation } from 'react-i18next'

import InformationCircle from './ImportBody/InformationCircle'

interface ImportPDFProps {
  percentageText: (value: number) => React.ReactNode
  sort: boolean
  setSort: (value: boolean) => void
  offset: number
  setOffset: (value: number) => void
  percentageOfTitles: number
  setPercentageOfTitles: (value: number) => void
  splitWords: boolean
  setSplitWords: (value: boolean) => void
  language: string | number
  setLanguage: (value: SegmentedValue) => void
}

const ImportPDF: React.FC<ImportPDFProps> = ({
  percentageText,
  sort,
  setSort,
  offset,
  setOffset,
  percentageOfTitles,
  setPercentageOfTitles,
  splitWords,
  setSplitWords,
  language,
  setLanguage,
}) => {
  const { t } = useTranslation()

  return (
    <section>
      <div className='flex items-center justify-between gap-2'>
        <div className='flex gap-1'>
          <span className='flex text-sm'>{t('sort-title')}</span>
          <InformationCircle text={t('sort-description')} />
        </div>
        <Switch
          onChange={(checked: boolean) => setSort(checked)}
          checked={sort}
          className='bg-on-surface/20'
        />
      </div>
      <Divider dashed className='my-3' />
      <div>
        <div className='flex gap-1'>
          <span className='flex text-sm'>{t('header-footer-offset')}</span>
          <InformationCircle text={t('header-footer-offset-desc')} />
        </div>
        <Slider
          defaultValue={70.0}
          min={0.0}
          max={400.0}
          step={10.0}
          value={offset}
          onChange={setOffset}
        />
      </div>
      <Divider dashed className='my-3' />
      <div>
        <div className='flex gap-1'>
          <span className='flex text-sm'>{t('percentage-of-titles')}</span>
          <InformationCircle text={t('percentage-of-titles-desc')} />
        </div>
        <Slider
          defaultValue={0.1}
          tooltip={{ formatter: percentageText as Formatter }}
          min={0.0}
          max={1.0}
          step={0.05}
          value={percentageOfTitles}
          onChange={setPercentageOfTitles}
        />
      </div>
      <Divider dashed className='my-3' />
      <div className='flex justify-between gap-2'>
        <div className='flex gap-1'>
          <span className='flex text-sm'>{t('split-words')}</span>
          <InformationCircle text={t('split-words-desc')} />
        </div>
        <Switch
          onChange={(checked: boolean) => setSplitWords(checked)}
          checked={splitWords}
          className='bg-on-surface/20'
        />
      </div>
      <Divider dashed className='my-3' />
      <div className='flex justify-between gap-2'>
        <div className='flex items-center gap-1'>
          <span className='flex text-sm'>{t('language')}</span>
          <InformationCircle text={t('language-desc')} />
        </div>
        <Segmented
          options={[
            {
              label: 'EN',
              value: 'en',
            },
            {
              label: 'SLO',
              value: 'si',
            },
          ]}
          value={language}
          onChange={setLanguage}
        />
      </div>
    </section>
  )
}

export default ImportPDF
