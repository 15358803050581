import { createContext } from 'react'

import { ISections } from '@/hooks/useSections'

import { ExtractedSectionsProps } from '@/context/extractedSections/extractedSections.types'

interface IExtractedSectionsContext {
  extractedSections: ISections
}

export const initialState: IExtractedSectionsContext = {
  extractedSections: { sections: [], totalCount: 0 },
}

export const ExtractedSectionsContext = createContext<ExtractedSectionsProps>({
  ...initialState,
  setExtractedSectionsState: () => undefined,
})
