import { Slider } from 'antd'
import { Formatter } from 'antd/es/slider'
import { useTranslation } from 'react-i18next'

import InformationCircle from './ImportBody/InformationCircle'

interface ImportDOCXProps {
  percentageText: (value: number) => React.ReactNode
  percentageOfTitles: number
  setPercentageOfTitles: (value: number) => void
}

const ImportDOCX: React.FC<ImportDOCXProps> = ({
  percentageText,
  percentageOfTitles,
  setPercentageOfTitles,
}) => {
  const { t } = useTranslation()

  return (
    <section>
      <div className='flex items-center gap-1'>
        <span className='text-sm'>{t('percentage-of-titles')}</span>
        <InformationCircle text={t('percentage-of-titles-desc')} />
      </div>
      <Slider
        defaultValue={0.1}
        tooltip={{ formatter: percentageText as Formatter }}
        min={0.0}
        max={1.0}
        step={0.05}
        value={percentageOfTitles}
        onChange={setPercentageOfTitles}
      />
    </section>
  )
}

export default ImportDOCX
