'use client'

import useModalsState from '@/hooks/context/useModalsState'

import CreateAgent from '@/components/Admin/Modals/CreateAgent'
import CreateSwarm from '@/components/Admin/Modals/CreateSwarm'

import AddDatasetModal from './AddDatasetModal'
import ExtractedSelectionsModal from './ExtractedSelectionsModal'
import FileHubModal from './FileHubModal'
import InviteUserModal from './InviteUserModal'

const ModalsContainer = () => {
  const { openModal } = useModalsState()

  return (
    <>
      {openModal === 'add-dataset' && <AddDatasetModal />}
      {openModal === 'filehub' && <FileHubModal />}
      {openModal === 'extractedSections' && <ExtractedSelectionsModal />}
      {openModal === 'inviteUser' && <InviteUserModal />}
      {openModal === 'create-agent' && <CreateAgent />}
      {openModal === 'create-swarm' && <CreateSwarm />}
    </>
  )
}

export default ModalsContainer
