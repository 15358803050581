import { RiCloseLine, RiSave3Line } from '@remixicon/react'
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
  UploadProps,
} from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'
import useAgents, { mutateAllAgents } from '@/hooks/useAgents'

import InformationCircle from '@/components/Admin/Modals/FileHub/ImportBody/InformationCircle'

import { MAX_AVATAR_SIZE } from '@/constants/admin'
import { configFileHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IAxiosError } from '@/types/api'

const CreateAgent = () => {
  const { agents } = useAgents()
  const [name, setName] = useState<string | undefined>('')
  const [inheritance, setInheritance] = useState<string[] | undefined>([])
  const [avatar, setAvatar] = useState<Array<UploadFile> | undefined>(undefined)

  const { t } = useTranslation()
  const { openModal, toggleModal } = useModalsState()
  const router = useRouter()

  const props: UploadProps = {
    beforeUpload: (file) => {
      const totalImportSize = file.size / 1024

      if (totalImportSize > MAX_AVATAR_SIZE) {
        message.error(t('toast-file-too-large'))
        return Upload.LIST_IGNORE
      }

      const isPNG = file.type === 'image/png'
      const isJPG = file.type === 'image/jpeg'
      const isJPEG = file.type === 'image/jpeg'
      const isGIF = file.type === 'image/gif'
      const isSVG = file.type === 'image/svg+xml'
      if (!(isPNG || isJPG || isJPEG || isGIF || isSVG)) {
        message.error(`${file.name} wrong file format`)
      }
      return isPNG || isJPG || isJPEG || isGIF || isSVG || Upload.LIST_IGNORE
    },
  }

  const saveAgent = async () => {
    message.loading(t('toast-loading'))

    try {
      const formData = new FormData()
      const files = avatar ? Array.from(avatar) : []
      if (files.length > 0 && files[0]) {
        const file = files[0].originFileObj
        if (file) {
          formData.append('avatar', file, file.name)
        }
      }

      const config = {
        method: 'post',
        ...configFileHeader,
        data: formData,
        withCredentials: true,
        params: {
          name,
          inheritance,
        },
      }

      const res = await axios(`${API_URL}/agent/insert`, config)

      message.success(t('agent-created-success'))
      toggleModal('create-agent')

      await mutateAllAgents()
      router.push(`/admin/agents/${res.data.agent.id}`)
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
  }

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setAvatar(newFileList)
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <Modal
      centered
      open={openModal === 'create-agent'}
      onCancel={() => toggleModal('create-agent')}
      title={t('agent-create-new')}
      footer={null}
      width={600}
      className='rounded-lg border border-on-background/20'
    >
      <div className='flex flex-col gap-3 pt-4'>
        <div className='gap-3 sm:flex-row'>
          <Row>
            <Col span={8} className='flex items-center'>
              {t('agent-name-title')}
            </Col>
            <Col span={16}>
              <Input
                value={name}
                placeholder={t('agent-name')}
                onChange={(value) => setName(value.target.value)}
              />
            </Col>
          </Row>
          <Row className='pt-2'>
            <Col span={8} className='flex items-center'>
              {t('agent-inheritance-title')}
            </Col>
            <Col span={16}>
              <Select
                className='w-full'
                mode='multiple'
                allowClear
                placeholder={t('agent-inheritance-placeholder')}
                value={inheritance}
                onChange={(value) => setInheritance(value)}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={agents?.map((a) => ({
                  label: a.name,
                  value: a.id,
                }))}
              />
            </Col>
          </Row>
          <Row className='pt-2'>
            <Col span={8}>
              <div className='flex min-w-max items-center gap-1'>
                {t('agent-avatar')}
                <InformationCircle text={t('agent-avatar-desc')} />
              </div>
            </Col>
            <Col span={16}>
              <ImgCrop rotationSlider modalClassName='img-crop'>
                <Upload
                  {...props}
                  maxCount={1}
                  fileList={avatar}
                  onPreview={onPreview}
                  onChange={onChange}
                  listType='picture-card'
                >
                  {avatar === undefined || avatar?.length < 1
                    ? '+ Upload'
                    : undefined}
                </Upload>
              </ImgCrop>
            </Col>
          </Row>
        </div>
      </div>
      <div className='mt-4 flex justify-end gap-2 text-xs'>
        <Button
          className='flex items-center px-3'
          icon={<RiSave3Line className='size-5' />}
          onClick={saveAgent}
          disabled={name === undefined || name === '' || name.length < 1}
        >
          {t('save')}
        </Button>
        <Button
          className='flex items-center px-3'
          icon={<RiCloseLine className='size-5' />}
          onClick={() => {
            toggleModal('create-agent')
          }}
        >
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateAgent
