'use client'

import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

import useAgents from '@/hooks/useAgents'
import { IDocumentsAndTitles } from '@/hooks/useDocumentsAndTitles'
import useUsers from '@/hooks/useUsers'

interface FiltersProps {
  handleFiltersChange: (filters: {
    documents: string[]
    titles: string[]
    agents: string[]
    users: string[]
  }) => void
  filters: {
    documents: string[]
    titles: string[]
    agents: string[]
    users: string[]
  }
  documentsAndTitles?: IDocumentsAndTitles
  selectedTab?: string
}

const Filters: React.FC<FiltersProps> = ({
  filters,
  handleFiltersChange,
  documentsAndTitles,
  selectedTab,
}) => {
  const { t } = useTranslation()
  const { agents } = useAgents()
  const { users } = useUsers(0, 1000)

  return (
    <div className='flex w-full flex-col items-center gap-2 sm:w-full xl:max-w-[50rem] xl:flex-row'>
      <Select
        className='w-full text-left'
        mode='multiple'
        allowClear
        maxTagCount='responsive'
        placeholder={t('documents')}
        value={filters.documents}
        onChange={(value) =>
          handleFiltersChange({ ...filters, documents: value })
        }
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={documentsAndTitles?.documents?.map((document) => ({
          label: document,
          value: document,
        }))}
      />
      <Select
        className='w-full text-left'
        mode='multiple'
        allowClear
        maxTagCount='responsive'
        placeholder={t('titles')}
        value={filters.titles}
        onChange={(value) => handleFiltersChange({ ...filters, titles: value })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={documentsAndTitles?.titles?.map((title) => ({
          label: title,
          value: title,
        }))}
      />
      {selectedTab === '1' ? (
        <Select
          className='w-full text-left'
          mode='multiple'
          allowClear
          maxTagCount='responsive'
          placeholder={t('agent-page')}
          value={filters.agents}
          onChange={(value) =>
            handleFiltersChange({ ...filters, agents: value })
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          options={agents?.map((agent) => ({
            label: agent.name,
            value: agent.name,
          }))}
        />
      ) : (
        <Select
          className='w-full text-left'
          mode='multiple'
          allowClear
          maxTagCount='responsive'
          placeholder={t('users')}
          value={filters.users}
          onChange={(value) =>
            handleFiltersChange({ ...filters, users: value })
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          options={users.users.map((user) => ({
            label: user.email,
            value: user.email,
          }))}
        />
      )}
    </div>
  )
}

export default Filters
