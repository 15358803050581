import { RiMailSendLine } from '@remixicon/react'
import { Button, Form, Input, message, Modal } from 'antd'
import axios from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'
import useAuth from '@/hooks/useAuth'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IAxiosError } from '@/types/api'

const InviteUserModal = () => {
  const { t } = useTranslation()
  const { openModal, toggleModal, reexecuteQuery } = useModalsState()
  const { user } = useAuth()

  const invite = async (values: any) => {
    message.loading(t('toast-loading'))
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        email: values.email,
        name: values.name,
        fromName: user?.name,
      }),
    }

    try {
      await axios(`${API_URL}/users/insert`, config)
      message.success(t('toast-user-new'))

      if (reexecuteQuery.users) {
        reexecuteQuery.users()
      }

      toggleModal('inviteUser')
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      open={openModal === 'inviteUser'}
      onCancel={() => toggleModal('inviteUser')}
      title={t('invite-modal-title')}
      footer={null}
      width={500}
      className='rounded-lg border border-on-background/20'
    >
      <Form
        name='basic'
        initialValues={{ remember: true }}
        onFinish={invite}
        autoComplete='off'
        className='pt-3'
        labelCol={{ span: 4 }}
      >
        <Form.Item
          label={t('invite-modal-email')}
          name='email'
          validateTrigger='onBlur'
          rules={[
            {
              required: true,
              message: t('invite-modal-email-message'),
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('invite-modal-name')}
          name='name'
          validateTrigger='onBlur'
          rules={[
            {
              required: true,
              message: t('invite-modal-name-message'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className='mt-4 flex justify-end gap-2 text-xs'>
          <Button
            htmlType='submit'
            className='fade-in flex items-center gap-2 px-3'
          >
            <RiMailSendLine className='size-5' />
            <div className='hidden md:block'>{t('invite-modal-confirm')}</div>
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default InviteUserModal
