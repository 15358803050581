import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { RiDeleteBinLine } from '@remixicon/react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useExtractedSectionsState from '@/hooks/context/useExtractedSectionsState'

import ExtractedSectionsRow from '@/components/Admin/Modals/ExtractedSections/ExtractedSectionsRow'

import SkeletonTable from '../../SkeletonTable'

const ExtractedSectionsTable: React.FC = () => {
  const [selected, setSelected] = useState<string[]>([])
  const [{ page, limit }, setPagination] = useState({ page: 0, limit: 5 })
  const { t } = useTranslation()
  const { extractedSections, setExtractedSectionsState } =
    useExtractedSectionsState()

  const handleChangePage = (event: unknown, newPage: number) => {
    setSelected([])
    setPagination({ limit, page: newPage })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelected([])
    setPagination({ page: 0, limit: +event.target.value })
  }

  const onSelectAllClick = () => {
    if (
      extractedSections &&
      selected.length !== limit &&
      selected.length !== extractedSections.totalCount
    ) {
      setSelected(
        extractedSections.sections
          .filter(
            (_, index) => index >= page * limit && index < (page + 1) * limit
          )
          .map((n) => n.id)
      )
    } else {
      setSelected([])
    }
  }

  useEffect(() => {
    setPagination({ page: 0, limit: 5 })
    setSelected([])
  }, [extractedSections])

  const deleteSelected = async () => {
    const modified_arr = extractedSections.sections.filter(
      (x) => !selected.includes(x.id)
    )
    setExtractedSectionsState({
      sections: modified_arr,
      totalCount: modified_arr.length,
    })
  }

  return (
    <Paper sx={{ width: '100%', boxShadow: 'none' }}>
      <TableContainer component={Paper} className='relative'>
        <Table size='small'>
          <TableHead className='bg-primary/20'>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={
                    selected.length === limit ||
                    selected.length === extractedSections.sections.length
                  }
                  onChange={onSelectAllClick}
                />
              </TableCell>
              <TableCell className='!font-bold'>{t('document')}</TableCell>
              <TableCell className='!font-bold'>{t('title')}</TableCell>
              <TableCell className='!font-bold'>{t('content')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {extractedSections.sections.length ? (
            <TableBody>
              {extractedSections?.sections
                .filter(
                  (_, index) =>
                    index >= page * limit && index < (page + 1) * limit
                )
                .map((row) => (
                  <ExtractedSectionsRow
                    key={row.id}
                    row={row}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
            </TableBody>
          ) : (
            <SkeletonTable numOfColumns={3} indent={1} />
          )}
        </Table>
        {!!selected.length && !!extractedSections.sections.length && (
          <div className='slide-from-right absolute bottom-2 left-5 z-40'>
            <Popconfirm
              title={t('multiple-select-title')}
              description={t('multiple-select-desc')}
              okText={t('multiple-select-confirm')}
              okType='danger'
              cancelText={t('multiple-select-deny')}
              onConfirm={deleteSelected}
            >
              <Tooltip placement='top' title={t('delete-selected')} arrow>
                <Button type='text' icon={<RiDeleteBinLine />} />
              </Tooltip>
            </Popconfirm>
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component='div'
          count={extractedSections?.totalCount ?? -1}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            (innerWidth >= 455 || (!selected.length && innerWidth < 455)) &&
            t('rows-per-page')
          }
        />
      </TableContainer>
    </Paper>
  )
}

export default ExtractedSectionsTable
