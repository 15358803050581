import { RiDownload2Line, RiUpload2Line } from '@remixicon/react'
import { Modal, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import ExportBody from './FileHub/ExportBody'
import ImportBody from './FileHub/ImportBody'

const FileHubModal: React.FC = () => {
  const { toggleModal, openModal } = useModalsState()
  const { t } = useTranslation()

  return (
    <Modal
      centered
      open={openModal === 'filehub'}
      onCancel={() => toggleModal('filehub')}
      footer={null}
      width={600}
      className='rounded-lg border border-on-background/20'
    >
      <Tabs
        type='card'
        size='small'
        items={[
          {
            label: (
              <div className='flex items-center gap-2'>
                <RiUpload2Line className='hidden size-4 sm:block' />
                {t('import')}
              </div>
            ),
            key: 'import',
            children: <ImportBody />,
          },
          {
            label: (
              <div className='flex items-center gap-2'>
                <RiDownload2Line className='hidden size-4 sm:block' />
                {t('export')}
              </div>
            ),
            key: 'export',
            children: <ExportBody />,
          },
        ]}
      />
    </Modal>
  )
}

export default FileHubModal
