import { RiCloseLine, RiSave3Line } from '@remixicon/react'
import { Button, Input, message, Select } from 'antd'
import axios from 'axios'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useExtractedSectionsState from '@/hooks/context/useExtractedSectionsState'
import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import ExtractedSectionsTable from '@/components/Admin/Modals/ExtractedSections/ExtractedSectionsTable'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import InformationCircle from '../FileHub/ImportBody/InformationCircle'

const TableLayout: React.FC = () => {
  const { t } = useTranslation()
  const { extractedSections, setExtractedSectionsState } =
    useExtractedSectionsState()

  const { toggleModal, reexecuteQuery } = useModalsState()
  const pathname = usePathname()
  const router = useRouter()
  const [url, setUrl] = useState('')
  const { agents, selectedAgent } = useAgents()
  const [agent, setAgent] = useState<string | undefined>('')

  useEffect(() => {
    setAgent(selectedAgent?.id)
  }, [selectedAgent])

  const handleSave = async () => {
    message.loading(t('toast-loading'))
    const data = {
      data: extractedSections.sections.map((section) => {
        return {
          document: section.document,
          title: section.title,
          content: section.content,
          agent,
        }
      }),
      googleDriveUrl: url !== '' ? url : undefined,
    }
    const config = {
      url: `${API_URL}/knowledge-base/sections/import/extracted`,
      method: 'post',
      data,
      withCredentials: true,
      ...configHeader,
    }
    try {
      await axios(config)
      setExtractedSectionsState({
        sections: [],
        totalCount: 0,
      })
      message.success(t('toast-import-success'))

      reexecuteQuery.sections && reexecuteQuery.sections()
      reexecuteQuery.documentsAndTitles && reexecuteQuery.documentsAndTitles()
      reexecuteQuery.documents && reexecuteQuery.documents()

      if (
        pathname !== '/admin/knowledge-base' &&
        pathname !== '/admin/documents'
      ) {
        router.push('/admin/knowledge-base')
      }

      toggleModal('extractedSections')
    } catch (error) {
      message.error(t('toast-import-failure'))
      console.error(error)
    }
  }

  const handleDiscard = () => {
    setExtractedSectionsState({ sections: [], totalCount: 0 })
    toggleModal('extractedSections')
  }

  return (
    <div className='relative flex flex-col gap-4 rounded sm:w-full sm:pr-0'>
      <header className='flex w-full flex-col items-center justify-between gap-2 p-4 sm:h-20 sm:flex-row'>
        <div className='flex flex-col gap-1 text-left'>
          <span className='font-semibold'>{t('extracted-sections')}</span>
          <span className='text-on-background/60'>
            {t('extracted-sections-description')}
          </span>
        </div>
      </header>
      <ExtractedSectionsTable />
      <div className='flex items-center justify-between gap-2'>
        <div className='flex gap-1 whitespace-nowrap'>
          <div className='text-sm'>{t('google-drive-url')}</div>
          <InformationCircle text={t('google-drive-url-info')} />
        </div>
        <Input
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder='Optional: https://drive.google.com/file/...'
        />
      </div>
      <div className='flex items-center justify-between gap-2'>
        <div
          className='flex gap-1 whitespace-nowrap'
          style={{ minWidth: 133.33 }}
        >
          <div className='flex text-sm'>{t('agent-select-new')}</div>
          <InformationCircle text={t('agent-select-info')} />
        </div>
        <Select
          value={agent}
          onChange={setAgent}
          style={{ width: '100%' }}
          placeholder={t('agent-select-new')}
          options={agents?.map((a) => ({
            label: a.name,
            value: a.id,
          }))}
        />
      </div>
      <footer className='flex gap-2'>
        <Button
          className='flex items-center p-3'
          onClick={handleSave}
          icon={<RiSave3Line className='size-5' />}
          disabled={!agent || agent === ''}
        >
          {t('import-sections')}
        </Button>
        <Button
          className='flex items-center p-3'
          onClick={handleDiscard}
          icon={<RiCloseLine className='size-5' />}
        >
          {t('cancel')}
        </Button>
      </footer>
    </div>
  )
}

export default TableLayout
