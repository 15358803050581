import { Button, message, Segmented } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDocumentsAndTitles from '@/hooks/useDocumentsAndTitles'

import SectionFilters from '@/components/Admin/KnowledgeBase/Filters'
import SectionSearch from '@/components/Admin/KnowledgeBase/Search'
import LogFilters from '@/components/Admin/Logs/Filters'
import LogSearch from '@/components/Admin/SearchField'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { offerFileAsDownload } from '@/utils/export'

import { ILogsFilters } from '@/types/admin'
import { ExportType } from '@/types/exports'

const ExportBody: React.FC = () => {
  const [exportType, setExportType] = useState<ExportType>(
    ExportType.KNOWLEDGE_BASE
  )
  const [options, setOptions] = useState<string | number>('all')
  const [filter, setFilter] = useState<string>('')
  const { documentsAndTitles } = useDocumentsAndTitles()

  const [logFilters, setLogFilters] = useState<ILogsFilters>({
    fromTimestamp: 0,
    toTimestamp: 0,
    ips: [],
    users: [],
  })

  const [useSemantic, setUseSemantic] = useState(false)
  const [sectionFilters, setSectionFilters] = useState<{
    documents: string[]
    titles: string[]
    agents: string[]
    users: string[]
  }>({ documents: [], titles: [], agents: [], users: [] })

  const { t } = useTranslation()

  const handleExportClick = async () => {
    message.loading(t('toast-loading'))

    const { fromTimestamp, toTimestamp, ips, types } = logFilters
    let requestBody = {}
    if (
      options === 'filter' &&
      (exportType === ExportType.QUESTION_LOGS ||
        exportType === ExportType.ACCESS_LOGS)
    ) {
      requestBody = {
        ...(filter.length > 0 && { search: filter }),
        ...(fromTimestamp &&
          toTimestamp &&
          fromTimestamp > 0 &&
          toTimestamp > 0 && {
            fromTimestamp,
            toTimestamp,
          }),
        ...(ips.length > 0 && { ips }),
        ...(exportType === ExportType.ACCESS_LOGS && {
          ...(types && types.length > 0 && { types }),
        }),
      }
    }
    if (options === 'filter' && exportType === ExportType.KNOWLEDGE_BASE) {
      const { documents, titles, agents } = sectionFilters

      requestBody = {
        useSemantic,
        ...(filter.length > 0 && { search: filter }),
        ...(documents.length > 0 && { documents }),
        ...(titles.length > 0 && { titles }),
        ...(agents.length > 0 && { agents }),
      }
    }

    const config = {
      method: 'post',
      withCredentials: true,
      data: JSON.stringify(requestBody),
      ...configHeader,
    }

    try {
      const res = await axios(
        exportType === ExportType.KNOWLEDGE_BASE
          ? `${API_URL}/knowledge-base/sections/export`
          : `${API_URL}/logs/${exportType}/export`,
        config
      )

      const dataBlob = new Blob([res.data])

      if (dataBlob) {
        offerFileAsDownload(dataBlob, exportType)
      }

      message.success(t('toast-export-success'))
    } catch (error) {
      message.error(t('toast-export-failure'))
      console.error(error)
    }
  }

  return (
    <div className='mt-4 flex flex-col justify-between gap-5'>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col items-start gap-1'>
          <div className='text-sm'>{t('database')}</div>
          <Segmented
            options={[
              {
                label: t('knowledge-base'),
                value: ExportType.KNOWLEDGE_BASE,
                disabled: false,
              },
              {
                label: t('questions-asked'),
                value: ExportType.QUESTION_LOGS,
                disabled: false,
              },
              {
                label: t('access-logs'),
                value: ExportType.ACCESS_LOGS,
                disabled: false,
              },
            ]}
            value={exportType}
            onChange={(value) => setExportType(value as ExportType)}
          />
        </div>
        <div className='flex flex-col items-start gap-1'>
          <div className='text-sm'>Options</div>
          <Segmented
            options={[
              {
                label: t('export-all'),
                value: 'all',
                disabled: false,
              },
              { label: t('filter'), value: 'filter', disabled: false },
            ]}
            value={options}
            onChange={setOptions}
          />
          {options === 'filter' && exportType === ExportType.QUESTION_LOGS && (
            <div className='flex w-full flex-col gap-2 pt-2'>
              <LogFilters
                handleFiltersChange={setLogFilters}
                filters={logFilters}
              />
              <LogSearch handleUserInputChange={setFilter} />
            </div>
          )}
          {options === 'filter' && exportType === ExportType.ACCESS_LOGS && (
            <div className='flex flex-col gap-2 pt-2 sm:w-full'>
              <LogFilters
                handleFiltersChange={setLogFilters}
                filters={logFilters}
                showTypeFilter
              />
              <LogSearch handleUserInputChange={setFilter} />
            </div>
          )}

          {options === 'filter' && exportType === ExportType.KNOWLEDGE_BASE && (
            <div className='flex w-full flex-col gap-2 pt-2'>
              <SectionFilters
                handleFiltersChange={setSectionFilters}
                filters={sectionFilters}
                documentsAndTitles={documentsAndTitles}
                selectedTab='1'
              />
              <SectionSearch
                handleUserInputChange={setFilter}
                useSemantic={useSemantic}
                setUseSemantic={setUseSemantic}
              />
            </div>
          )}
        </div>
      </div>

      <Button onClick={handleExportClick}>{t('export-button')}</Button>
    </div>
  )
}

export default ExportBody
