import { useContext } from 'react'

import { ExtractedSectionsContext } from '@/context/extractedSections/extractedSections.context'

const useExtractedSectionsState = () => {
  const extractedSectionsContext = useContext(ExtractedSectionsContext)
  if (!Object.keys(ExtractedSectionsContext).length) {
    throw new Error('ExtractedSectionsContext must be used within its provider')
  }
  return extractedSectionsContext
}

export default useExtractedSectionsState
