import { RiArticleLine, RiFileWordLine, RiGridLine } from '@remixicon/react'
import { Button, Input, message, Segmented, Select } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import axios from 'axios'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useExtractedSectionsState from '@/hooks/context/useExtractedSectionsState'
import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import ImportDOCX from '@/components/Admin/Modals/FileHub/ImportDOCX'
import ImportPDF from '@/components/Admin/Modals/FileHub/ImportPDF'

import { ImportType, MAX_IMPORT_SIZE } from '@/constants/admin'
import { configFileHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { percentageText } from '@/utils/upload'

import DragDropArea from './ImportBody/DragDropArea'
import InformationCircle from './ImportBody/InformationCircle'

import { ISection } from '@/types/admin'

const ImportBody = () => {
  const [formatValue, setFormatValue] = useState<ImportType>(ImportType.CSV)
  const [sort, setSort] = useState(true)
  const [offset, setOffset] = useState(70)
  const [percentageOfTitles, setPercentageOfTitles] = useState(0.1)
  const [splitWords, setSplitWords] = useState(false)
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [language, setLanguage] = useState<SegmentedValue>('en')
  const [url, setUrl] = useState('')
  const [agent, setAgent] = useState<string | undefined>()
  const { agents, selectedAgent } = useAgents()

  const { toggleModal, reexecuteQuery } = useModalsState()
  const { setExtractedSectionsState } = useExtractedSectionsState()
  const { t } = useTranslation()
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    if (selectedAgent && !agent) {
      setAgent(selectedAgent.id)
    }
  }, [selectedAgent])

  const wrongFormat = (file: File) => {
    return (
      (formatValue === ImportType.CSV && file.type !== 'text/csv') ||
      (formatValue === ImportType.PDF && file.type !== 'application/pdf') ||
      (formatValue === ImportType.DOCX &&
        file.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    )
  }

  const handleUploadClick = async () => {
    if (!fileList) {
      return
    }

    message.loading(t('toast-loading'))
    const files = fileList ? Array.from(fileList) : []
    const formData = new FormData()

    const totalImportSize = files.reduce((acc, file) => {
      return acc + file.size / 1024
    }, 0)

    if (totalImportSize > MAX_IMPORT_SIZE) {
      message.error(t('toast-import-failure-max-size'))
      return
    }

    for (const file of files) {
      if (wrongFormat(file)) {
        message.error(t('toast-import-failure-wrong-format'))
        return
      }

      formData.append(
        `${formatValue === ImportType.CSV ? 'sectionsFiles' : 'file'}`,
        file,
        file.name
      )

      if (formatValue === ImportType.PDF || formatValue === ImportType.DOCX) {
        toggleModal('extractedSections')
      }
    }

    const config = {
      url: `${API_URL}/knowledge-base/sections/${
        formatValue === ImportType.CSV ? 'import/csv' : 'extract'
      }`,
      method: 'post',
      data: formData,
      withCredentials: true,
      ...configFileHeader,
      params:
        formatValue === ImportType.DOCX
          ? { percentage_of_titles: percentageOfTitles, type: 'DOCX' }
          : formatValue === ImportType.PDF
            ? {
                sort,
                header_footer_offset: offset,
                percentage_of_titles: percentageOfTitles,
                split_words: splitWords,
                language,
                type: 'PDF',
              }
            : url !== ''
              ? { googleDriveUrl: url, agent }
              : undefined,
    }
    try {
      const res = await axios(config)
      if (formatValue === ImportType.CSV) {
        message.success(t('toast-import-success'))
        reexecuteQuery.sections && reexecuteQuery.sections()
        reexecuteQuery.documentsAndTitles && reexecuteQuery.documentsAndTitles()
        reexecuteQuery.documents && reexecuteQuery.documents()

        if (
          pathname !== '/admin/knowledge-base' &&
          pathname !== '/admin/documents'
        ) {
          router.push('/admin/knowledge-base')
        }
        toggleModal('filehub')
      } else {
        setExtractedSectionsState({
          sections: res.data.data
            .map((x: ISection, i: number) => {
              return { ...x, id: i }
            })
            .filter((x: ISection) => x.content && x.content.length),
          totalCount: res.data.data.length,
        })
        message.success(t('toast-extract-success'))
      }
    } catch (error) {
      if (formatValue === ImportType.CSV) {
        message.error(t('toast-import-failure'))
      } else {
        message.error(t('toast-extract-failure'))
      }
      console.error(error)
    }
  }

  return (
    <div className='mt-4 flex flex-col justify-between gap-5'>
      <div className='flex flex-col items-start gap-1'>
        <div className='text-sm'>Format</div>
        <Segmented
          options={[
            {
              label: 'CSV',
              value: ImportType.CSV,
              icon: <RiGridLine />,
            },
            {
              label: 'DOCX',
              value: ImportType.DOCX,
              icon: <RiFileWordLine />,
            },
            {
              label: 'PDF',
              value: ImportType.PDF,
              icon: <RiArticleLine />,
            },
          ]}
          value={formatValue}
          onChange={(value) => setFormatValue(value as ImportType)}
        />
      </div>
      <DragDropArea formatValue={formatValue} setFileList={setFileList} />
      {formatValue === ImportType.DOCX && (
        <ImportDOCX
          percentageText={percentageText}
          percentageOfTitles={percentageOfTitles}
          setPercentageOfTitles={setPercentageOfTitles}
        />
      )}
      {formatValue === ImportType.PDF && (
        <ImportPDF
          percentageText={percentageText}
          sort={sort}
          setSort={setSort}
          offset={offset}
          setOffset={setOffset}
          percentageOfTitles={percentageOfTitles}
          setPercentageOfTitles={setPercentageOfTitles}
          splitWords={splitWords}
          setSplitWords={setSplitWords}
          language={language}
          setLanguage={setLanguage}
        />
      )}
      {formatValue === ImportType.CSV && (
        <>
          <div className='flex items-center justify-between gap-2'>
            <div className='flex gap-1 whitespace-nowrap'>
              <div className='text-sm'>{t('google-drive-url')}</div>
              <InformationCircle text={t('google-drive-url-info')} />
            </div>
            <Input
              value={url}
              onChange={(event) => setUrl(event.target.value)}
              placeholder='Optional: https://drive.google.com/file/...'
            />
          </div>
          <div className='flex items-center justify-between gap-2'>
            <div className='flex gap-1 whitespace-nowrap'>
              <div className='text-admin-accent flex text-sm'>{t('agent')}</div>
              <InformationCircle text={t('agent-select-info')} />
            </div>
            <Select
              className='w-1/2'
              value={agent}
              onChange={(val) => setAgent(val)}
              placeholder={t('agent-select-new')}
              options={agents?.map((a) => ({
                label: a.name,
                value: a.id,
              }))}
            />
          </div>
        </>
      )}

      <Button
        onClick={handleUploadClick}
        disabled={!fileList || !fileList.length}
      >
        {t('import-button')}
      </Button>
    </div>
  )
}

export default ImportBody
