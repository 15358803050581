import {
  Checkbox,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material'
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiSave3Line,
} from '@remixicon/react'
import { Button, Input, Popconfirm } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useExtractedSectionsState from '@/hooks/context/useExtractedSectionsState'

import { cn } from '@/utils/clsx'

import TruncatedTableCell from '../../TruncatedTableCell'

import { ISection } from '@/types/admin'
const { TextArea } = Input

interface ExtractedSectionsRowProps {
  selected: string[]
  row: ISection
  setSelected: (setSelected: string[]) => void
}

const ExtractedSectionsRow: React.FC<ExtractedSectionsRowProps> = ({
  selected,
  row,
  setSelected,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState(Object.assign({}, row))

  const { t } = useTranslation()
  const { extractedSections, setExtractedSectionsState } =
    useExtractedSectionsState()

  const deleteSection = async () => {
    const modified_arr = extractedSections.sections.filter(
      (x) => x.id !== row.id
    )
    setExtractedSectionsState({
      sections: modified_arr,
      totalCount: modified_arr.length,
    })
  }

  const updateSection = () => {
    const modified_arr = extractedSections.sections.map((x) =>
      x.id === details.id
        ? {
            ...details,
            id: x.id,
          }
        : x
    )
    setExtractedSectionsState({
      sections: modified_arr,
      totalCount: modified_arr.length,
    })
    setEditMode(false)
    setOpen(false)
  }

  const onCheckboxClick = () => {
    if (selected.includes(row.id)) {
      setSelected(selected.filter((id) => id !== row.id))
    } else {
      setSelected([...selected, row.id])
    }
  }

  const expand = () => {
    if (!open) {
      setDetails(row)
    }
    setOpen(!open)
  }

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className={cn(
          'select-none',
          selected.includes(row.id) && 'bg-on-background/40'
        )}
      >
        <TruncatedTableCell sx={{ width: '40px' }}>
          <Checkbox
            color='primary'
            checked={selected.includes(row.id)}
            onChange={onCheckboxClick}
          />
        </TruncatedTableCell>
        <TruncatedTableCell
          sx={{ maxWidth: '50px', cursor: 'pointer' }}
          onClick={expand}
        >
          {row.document}
        </TruncatedTableCell>
        <TruncatedTableCell
          sx={{ maxWidth: '50px', cursor: 'pointer' }}
          onClick={expand}
        >
          {row.title}
        </TruncatedTableCell>
        <TruncatedTableCell
          sx={{ maxWidth: '150px', cursor: 'pointer' }}
          onClick={expand}
        >
          {row.content}
        </TruncatedTableCell>
        <TruncatedTableCell
          sx={{ width: '50px', cursor: 'pointer' }}
          onClick={expand}
        >
          <IconButton aria-label='expand row' size='small'>
            {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
          </IconButton>
        </TruncatedTableCell>
      </TableRow>
      <TableRow>
        <TableCell padding='none' colSpan={5}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <div className='flex w-full flex-col gap-4 p-5'>
              <div className='flex flex-col gap-4 sm:flex-row'>
                <div className='flex w-full flex-col gap-1'>
                  <span className='font-semibold'>{t('document')}</span>
                  <Input
                    value={details.document}
                    disabled={!editMode}
                    className='w-full'
                    onChange={(event) =>
                      setDetails({ ...details, document: event.target.value })
                    }
                  />
                </div>
                <div className='flex w-full flex-col gap-1'>
                  <span className='font-semibold'>{t('title')}</span>
                  <Input
                    value={details.title}
                    disabled={!editMode}
                    className='w-full'
                    onChange={(event) =>
                      setDetails({ ...details, title: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className='flex w-full flex-col gap-1'>
                <span className='font-semibold'>{t('content')}</span>
                <TextArea
                  value={details.content}
                  autoSize={{ minRows: 4 }}
                  disabled={!editMode}
                  className='w-full'
                  onChange={(event) =>
                    setDetails({ ...details, content: event.target.value })
                  }
                />
              </div>

              {!editMode ? (
                <div className='flex gap-2'>
                  <Button
                    className='flex items-center px-3'
                    icon={<RiPencilLine className='size-5' />}
                    onClick={() => setEditMode(!editMode)}
                  >
                    {t('edit')}
                  </Button>
                  <Popconfirm
                    title={t('single-delete-title')}
                    description={t('multiple-select-desc')}
                    okText={t('multiple-select-confirm')}
                    okType='danger'
                    cancelText={t('multiple-select-deny')}
                    onConfirm={deleteSection}
                  >
                    <Button
                      className='flex items-center px-3'
                      danger
                      icon={<RiDeleteBinLine className='size-5' />}
                    >
                      {t('delete')}
                    </Button>
                  </Popconfirm>
                </div>
              ) : (
                <div className='flex gap-2'>
                  <Button
                    className='flex items-center px-3'
                    icon={<RiSave3Line className='size-5' />}
                    onClick={updateSection}
                  >
                    {t('save')}
                  </Button>
                  <Button
                    className='flex items-center px-3'
                    icon={<RiCloseLine className='size-5' />}
                    onClick={() => setEditMode(false)}
                  >
                    {t('cancel')}
                  </Button>
                </div>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ExtractedSectionsRow
