import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

export type IDocumentsAndTitles = {
  documents: string[]
  titles: string[]
}

const useDocumentsAndTitles = () => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    ['documentsAndTitles'],
    async () => axios(`${API_URL}/knowledge-base/documents-and-titles`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    documentsAndTitles: data?.data,
    isLoading,
    error,
    mutate,
  }
}

export default useDocumentsAndTitles
