import { TableBody, TableCell, TableRow } from '@mui/material'
import SkeletonInput from 'antd/es/skeleton/Input'
import React from 'react'

interface SkeletonProps {
  numOfColumns?: number
  numOfRows?: number
  indent?: number
}

const SkeletonTable: React.FC<SkeletonProps> = ({
  numOfColumns = 3,
  numOfRows = 5,
  indent = 0,
}) => {
  return (
    <TableBody>
      {[...Array(numOfRows)].map((_x, index) => (
        <TableRow
          key={index}
          sx={{ '& > *': { borderBottom: 'unset' } }}
          className='fade-in'
        >
          {[...Array(indent)].map((_x, index) => (
            <TableCell key={index} />
          ))}
          {[...Array(numOfColumns)].map((_x, index) => (
            <TableCell key={index} className='h-[48px]'>
              <SkeletonInput active size='small' />
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      ))}
    </TableBody>
  )
}

export default SkeletonTable
