import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'

import SearchField from '../SearchField'

interface SearchProps {
  useSemantic: boolean
  handleUserInputChange: (value: string) => void
  setUseSemantic: (value: boolean) => void
}

const Search: React.FC<SearchProps> = ({
  handleUserInputChange,
  useSemantic,
  setUseSemantic,
}) => {
  const { t } = useTranslation()

  return (
    <div className='flex w-full select-none items-center gap-2'>
      <Checkbox
        checked={useSemantic}
        onChange={(event) => setUseSemantic(event.target.checked)}
      >
        {t('semantic')}
      </Checkbox>
      <SearchField handleUserInputChange={handleUserInputChange} />
    </div>
  )
}

export default Search
