import { Modal } from 'antd'

import useModalsState from '@/hooks/context/useModalsState'

import TableLayout from './ExtractedSections/ExtractedSectionsLayout'

const ExtractedSelectionsModal: React.FC = () => {
  const { toggleModal, openModal } = useModalsState()

  return (
    <Modal
      centered
      open={openModal === 'extractedSections'}
      onCancel={() => toggleModal('extractedSections')}
      footer={null}
      width='100%'
      className='rounded-lg border border-on-background/20'
    >
      <TableLayout />
    </Modal>
  )
}

export default ExtractedSelectionsModal
