import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IUser } from '@/types/admin'

export type IUsers = {
  users: IUser[]
  totalCount: number
}

const useUsers = (
  page: number,
  limit: number,
  search?: string,
  filters?: { roles: string[] }
) => {
  const config = {
    method: 'post',
    withCredentials: true,
    ...configHeader,
    data: JSON.stringify({
      page,
      limit,
      search: search !== '' ? search : undefined,
      roles: filters && filters.roles.length > 0 ? filters?.roles : undefined,
    }),
  }

  const { data, error, isLoading, mutate } = useSWR(
    ['users', page, limit, search, filters],
    async () => axios(`${API_URL}/users`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    users: data?.data as IUsers,
    isLoading,
    error,
    mutate,
  }
}

export default useUsers
