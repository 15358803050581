import { RiInboxArchiveLine } from '@remixicon/react'
import { Upload } from 'antd'
import { t } from 'i18next'
import React from 'react'

import { ImportType } from '@/constants/admin'
import { convertToFileList } from '@/utils/upload'

const { Dragger } = Upload

interface DragDropAreaProps {
  formatValue: ImportType
  setFileList: (fileList: FileList | null) => void
}

const DragDropArea: React.FC<DragDropAreaProps> = ({
  formatValue,
  setFileList,
}) => (
  <Dragger
    beforeUpload={() => false}
    onChange={(e) => setFileList(convertToFileList(e.fileList))}
    multiple
    accept={`.${formatValue}`}
    className='flex flex-col justify-center gap-2'
  >
    <p className='ant-upload-drag-icon flex justify-center'>
      <RiInboxArchiveLine className='size-16' />
    </p>
    <p className='ant-upload-text'>{t('drag-drop-text')}</p>
    <p className='ant-upload-hint'>{t('drag-drop-hint')}</p>
  </Dragger>
)

export default DragDropArea
