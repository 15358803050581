'use client'

import { createTheme, ThemeProvider } from '@mui/material'

import { colors, mode } from '@/branding-config'

interface MuiProviderProps {
  children: React.ReactNode
}

const MuiProvider: React.FC<MuiProviderProps> = ({ children }) => {
  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MuiProvider
