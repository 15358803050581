'use client'

import { memo, PropsWithChildren, useMemo, useState } from 'react'

import { ISections } from '@/hooks/useSections'

import {
  ExtractedSectionsContext,
  initialState,
} from '@/context/extractedSections/extractedSections.context'

const ExtractedSectionsProvider = memo(({ children }: PropsWithChildren) => {
  const [extractedSections, setExtractedSections] = useState(
    initialState.extractedSections
  )

  const setExtractedSectionsState = (state: ISections) => {
    setExtractedSections(state)
  }

  const contextValue = useMemo(() => {
    return {
      extractedSections,
      setExtractedSectionsState,
    }
  }, [extractedSections])

  return (
    <ExtractedSectionsContext.Provider value={contextValue}>
      {children}
    </ExtractedSectionsContext.Provider>
  )
})

export default ExtractedSectionsProvider
