'use client'

import { Tour, TourProps } from 'antd'
import { memo, PropsWithChildren, useMemo, useRef, useState } from 'react'

import { TourContext } from './tour.context'

export const TourProvider = memo(({ children }: PropsWithChildren) => {
  const [openTour, setOpenTour] = useState(false)
  const tourRef1 = useRef(null)
  const tourRef2 = useRef(null)
  const tourRef3 = useRef(null)

  const tourSteps: TourProps['steps'] = [
    {
      title: 'Interact with the Chatbot',
      description: 'Type your question here.',
      target: () => tourRef1.current,
    },
    {
      title: 'Upload files',
      description:
        'Drag and drop files in the input box or click this icon to upload files.',
      target: () => tourRef2.current,
    },
    {
      title: 'Settings',
      description:
        'Click here to change the settings of the chatbot. You can switch between GPT-3 and GPT-4 models, enable/disable streaming and drafting, or select preferred language.',
      target: () => tourRef3.current,
    },
  ]

  const startTour = () => {
    const tourFinished = localStorage.getItem('tourFinished')
    if (!tourFinished || tourFinished !== 'true') {
      setOpenTour(true)
    }
  }

  const finishTour = () => {
    setOpenTour(false)
    localStorage.setItem('tourFinished', 'true')
  }

  const contextValue = useMemo(() => {
    return {
      tourRef1,
      tourRef2,
      tourRef3,
      tourSteps,
      openTour,
      startTour,
    }
  }, [tourRef1, tourRef2, tourRef3, tourSteps, openTour])

  return (
    <TourContext.Provider value={contextValue}>
      {children}
      <Tour
        open={openTour}
        onClose={finishTour}
        steps={tourSteps}
        onFinish={finishTour}
      />
    </TourContext.Provider>
  )
})
